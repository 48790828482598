export default {
    namespaced: true,

    state:{
        news:[],
        contact:[],
        email:[],
        order:[],
        stockCheck:[],
        stockOut:[],
        lowstock:[],
    },
    getters:{
        news(state){
            return state.news.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        contact(state){
            return state.contact.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        email(state){
            return state.email.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        stockCheck(state){
            return state.stockCheck.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        stockOut(state){
            return state.stockOut.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        lowstock(state){
            return state.lowstock.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        order(state){
            return state.order.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },
    mutations:{
        setNews(state, news){
            state.news = news;
        },
        setContact(state, contact){
            state.contact = contact;
        },
        setEmail(state, email){
            state.email = email;
        },
        setStockCheck(state, stockCheck){
            state.stockCheck = stockCheck;
        },
        setStockOut(state, stockOut){
            state.stockOut = stockOut;
        },
        setLowStock(state, lowstock){
            state.lowstock = lowstock;
        },
        setOrder(state, order){
            state.order = order;
        },
    },
    actions:{
        async updateOrder(context, data) {
            await axios.post(`${this.state.host}/update-order`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getOrder(context, data) {
            context.commit('setOrder', []);
            await axios.post(`${this.state.host}/get-order`, data,{
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setOrder', res.data.order);
                    return res.data.order;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getStockCheck(context) {
            let contact = await axios.get(`${this.state.host}/get-stock`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setStockCheck', res.data.stockCheck);
                    return res.data.stockCheck;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getStockOut(context) {
            let contact = await axios.get(`${this.state.host}/get-stock-out`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setStockOut', res.data.stockout);
                    return res.data.stockout;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getLowStock(context) {
            let contact = await axios.get(`${this.state.host}/get-low-stock`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setLowStock', res.data.lowstock);
                    return res.data.lowstock;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getEmail(context) {
            let contact = await axios.get(`${this.state.host}/get-email`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setEmail', res.data.email);
                    return res.data.email;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getContact(context) {
            let contact = await axios.get(`${this.state.host}/get-contact`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setContact', res.data.contact);
                    return res.data.contact;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
        },
        async getNews(context) {
            await axios.get(`${this.state.host}/get-news`, {
            headers: {
                Authorization: this.getters['user/GET_AUTH_TOKEN'],
            },
            }).then(res => {
                context.commit('setNews', res.data.news);
                return res.data.news;
            }).catch(error => {
                this.dispatch('notification/error', error);
            });
        },
        async saveNews(context, {news, image,description}) {
            let isSuccess = false;
            let formData = new FormData();
                formData.append('news', JSON.stringify(news));
                formData.append('image', image);
                formData.append('description', description);
                await axios.post(`${this.state.host}/store-news`, formData, {
                    headers: {
                        Authorization: this.getters['user/GET_AUTH_TOKEN'],
                    },
                })
                .then(res => {
                    context.dispatch('getNews');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
    }
}