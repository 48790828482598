export default {
    namespaced: true,

    state: {
        auth_status: false,
        auth_token: null,
        auth_info:{
            id: null,
            name: null,
            email:null,
            permission:null,
            type:null,
        },
        logoutTimer: null, 
    },
    getters: {
      GET_AUTH_STATUS(state){
          return state.auth_status;
      },
      GET_AUTH_TOKEN(state){
          return state.auth_token;
      },
      GET_AUTH_INFO(state){
          return state.auth_info;
      },
    },
    actions: {
        async login(context, user) {
          try{
              const response = await axios.post(`${this.state.host}/login`, user);
              this.dispatch('usermanagment/getPermissionData', {user_id: response.data.data.user.id});
              context.commit('SET_AUTH_TOKEN',response.data.data.access_token)
              context.commit('SET_AUTH_INFO',response.data.data.user)
              localStorage.setItem('admin_token', response.data.data.access_token)
              this.dispatch('notification/success', 'Login Successfully');
              return true;
          } catch (error) {
            this.dispatch('notification/error', error.response.data.message);
            return false;
          }
        },
      async logout(context) {
              await axios.post(`${this.state.host}/logout`, context.state.auth_token, {
                headers: {
                  Authorization: context.state.auth_token,
                },
            })
            .then(res => {
                this.dispatch('notification/success', res.data.message);
                context.commit('SET_AUTH_LOGOUT');
            })
            .catch(error => {
              this.dispatch('notification/error', error.response.data.message);
            })
          },
        forceLogout(context) {
          context.commit('SET_AUTH_LOGOUT');
        },
    },
    mutations: {
        SET_AUTH_TOKEN(state,token){
          let tokenData = localStorage.getItem('admin_token');
          state.auth_token =  `Bearer ${token}`;
          state.auth_status =true;
        },
        SET_AUTH_INFO(state,info){
          state.auth_info.id = info.id;
          state.auth_info.name = info.name;
          state.auth_info.email = info.email;
          state.auth_info.permission = info.permission;
          state.auth_info.type = info.type;
        },
        SET_AUTH_LOGOUT(state){
          localStorage.setItem('admin_token', '');
          state.auth_token =null;
          state.auth_status =false;
          state.auth_info = {
            id: null,
            name: null,
            email:null,
            permission:null,
            type:null,
          } 
        }
    },
}
