export default {
    namespaced: true,

    state: {
        sliders: [],
        featureds: [],
        promo:[],
        
    },
    getters: {
        sliders(state) {
            return state.sliders.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        featureds(state) {
            return state.featureds.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        promo(state) {
            return state.promo;
        },
    },

    mutations: {
        setSlider(state, sliders) {
            state.sliders = sliders;
        },
        setFeatureds(state, featureds) {
            state.featureds = featureds;
        },
        setPrmo(state, promo) {
            state.promo = promo;
        },
    },
    actions: {
        async getSlider(context) {
            let sliders = await axios.get(`${this.state.host}/get-slider`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.sliders;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setSlider', sliders);
        },
        async getPromoVideo(context) {
            let promo = await axios.get(`${this.state.host}/get-promo-video`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setPrmo', res.data.promo);
                    return res.data.promo;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            
        },
        async getFeaturedProduct(context) {
            let featuredProducts = await axios.get(`${this.state.host}/get-featured-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.featuredProducts;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setFeatureds', featuredProducts);
        },
        async featuredDelete(context, category) {
            await axios.post(`${this.state.host}/featured-delete`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getFeaturedProduct');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async deleteSlider(context, category) {
            await axios.post(`${this.state.host}/delete-slider`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getSlider');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async savePromotioanlVideo(context, {slider, image}) {
            let isSuccess = false;
            let formData = new FormData();
                formData.append('slider', JSON.stringify(slider));
                formData.append('images', image);
            await axios.post(`${this.state.host}/store-promotioanl-video`, formData, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getSlider');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async saveSlider(context, {slider, image}) {
            let isSuccess = false;
            let formData = new FormData();
                formData.append('slider', JSON.stringify(slider));
                formData.append('images', image);
            await axios.post(`${this.state.host}/store-slider`, formData, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getSlider');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
    }
}
