import { createStore } from "vuex";
import user from './user';
import product from './product';
import category from "./category";
import menu from "./menu";
import home from './home'
import aditonal from "./aditonal";
import usermanagment from "./usermanagment";
import dashboard from "./dashboard";
import notification from "./notification";
import createPersistedState from 'vuex-persistedstate';
import useConfig from '../composable/useConfig';

const { host, hostName } = useConfig()
export default createStore({
    state: () => {
        return {
            host: host,
            hostName: hostName,
        }
    },

    getters: {

    },

    mutations: {

    },

    actions: {

    },

    modules: {
        user,
        product,
        notification,
        category,
        menu,
        usermanagment,
        dashboard,
        home,
        aditonal,
    },
    plugins: [createPersistedState({
        paths: ['user']
      })],
})