export default {
    namespaced: true,

    state: {
        products: [],
        edit: [],
        helmets: [],
        helmatsProduct: [], 
        offerProduct: [], 
        stockmanage: [], 
        
    },
    getters: {
        products(state) {
            return state.products.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        helmets(state) {
            return state.helmets.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        helmatsProduct(state) {
            return state.helmatsProduct.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        offerProduct(state) {
            return state.offerProduct.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        stockmanage(state) {
            return state.stockmanage.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        edit(state) {
            return state.edit;
        },
    },

    mutations: {
        setProduct(state, products) {
            state.products = products;
        },
        setHelmets(state, helmets) {
            state.helmets = helmets;
        },
        setHelmatsProduct(state, helmatsProduct) {
            state.helmatsProduct = helmatsProduct;
        },
        setOfferProduct(state, offerProduct) {
            state.offerProduct = offerProduct;
        },
        setStockmanage(state, stockmanage) {
            state.stockmanage = stockmanage;
        },
        seteditdata(state, edit) {
            state.edit = edit;
        },
    },
    actions: {
        
        async getManageStock(context, category) {
            await axios.get(`${this.state.host}/get-manage-stock`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                console.log(res)
                context.commit('setStockmanage', res.data.stockmanage);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async saveVariant(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/save-stock`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getManageStock');
                this.dispatch('notification/success', res.data.data);
                return isSuccess = true;
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
                return isSuccess = false;
            });
            return isSuccess;
        },
        async deleteHemateProduct(context, category) {
            await axios.post(`${this.state.host}/delete-helmet-product`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getHelmateProduct');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        productEdit(context, editdata){
            context.commit('seteditdata', editdata);
        },
        async getOfferProduct(context) {
            let offerProduct = await axios.get(`${this.state.host}/get-offer-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.offerProduct;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setOfferProduct', offerProduct);
        },
        async getHelmetProduct(context) {
            let helmets = await axios.get(`${this.state.host}/get-helmet-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.helmets;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setHelmets', helmets);
        },
        async getProduct(context) {
            let products = await axios.get(`${this.state.host}/get-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.products;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setProduct', products);
        },
        async getHelmateProduct(context) {
            let helmatsProduct = await axios.get(`${this.state.host}/get-helmate-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.helmatsProduct;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setHelmatsProduct', helmatsProduct);
        },
        async saveOfferProduct(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-offer-product`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getOfferProduct');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async saveHelmateProduct(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-helmate-product`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getHelmateProduct');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async savefeatured(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-featured-product`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async deleteProductImage(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/delete-product-image`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async saveProduct(context, {product, image, multiimage,description}) {
            let isSuccess = false;
            let formData = new FormData();
            formData.append('product', JSON.stringify(product));

                multiimage.forEach(file => {
                    formData.append('multiimage[]', file);
                });


                formData.append('images', image);
                formData.append('description', description);
            await axios.post(`${this.state.host}/store-product`, formData, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    console.log(res);
                    // context.dispatch('getChildCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async updateProduct(context, {product, image, multiimage,description}) {
            let isSuccess = false;
            let formData = new FormData();
            formData.append('product', JSON.stringify(product));

                multiimage.forEach(file => {
                    formData.append('multiimage[]', file);
                });


                formData.append('images', image);
                formData.append('description', description);
            await axios.post(`${this.state.host}/update-product`, formData, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    console.log(res);
                    // context.dispatch('getChildCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
    }
}
