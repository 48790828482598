export default {
    namespaced: true,

    state: {
        categories: [],
        subcategories: [],
        childcategories: [],
        categoryproduct: [],
        brands: [],
        helmets: [],
        offercategory: [],
    },
    getters: {
        categories(state) {
            return state.categories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        offercategory(state) {
            return state.offercategory.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        subcategories(state) {
            return state.subcategories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        childcategories(state) {
            return state.childcategories.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        categoryproduct(state) {
            return state.categoryproduct.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        brands(state) {
            return state.brands.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
        helmets(state) {
            return state.helmets.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },
    mutations: {
        setCategory(state, categories) {
            state.categories = categories;
        },
        setOfferCategory(state, offercategory) {
            state.offercategory = offercategory;
        },
        setSubCategory(state, subcategories) {
            state.subcategories = subcategories;
        },
        setChildCategory(state, childcategories) {
            state.childcategories = childcategories;
        },
        setCategoryProduct(state, categoryproduct) {
            state.categoryproduct = categoryproduct;
        },
        setBrands(state, brands) {
            state.brands = brands;
        },
        setHelmets(state, helmets) {
            state.helmets = helmets;
        },
    },
    actions: {
        async brandDelete(context, category) {
            await axios.post(`${this.state.host}/delete-brand`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                context.dispatch('getBrand');
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async catagoryDelete(context, category) {
            await axios.post(`${this.state.host}/delete-category`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
            .then(res => {
                if(category.type == 1){
                    context.dispatch('getCategories');
                }else if(category.type == 2){
                    context.dispatch('getSubCategories');
                }else{
                    context.dispatch('getChildCategories');
                }
                this.dispatch('notification/success', res.data.data);
            })
            .catch(error => {
                this.dispatch('notification/error', error.response.data.message);
            });
        },
        async getBrand(context) {
            let brands = await axios.get(`${this.state.host}/get-brands`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.brands;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setBrands', brands);
        },
        async getOfferCategory(context) {
            let offercategory = await axios.get(`${this.state.host}/get-offer-category`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.commit('setOfferCategory', res.data.offercategory);
                    return res.data.offercategory;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });
            },
        async getCategoriesProduct(context) {
            let categories = await axios.get(`${this.state.host}/get-category-for-product`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.categories;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setCategoryProduct', categories);
        },
        async getCategories(context) {
            let categories = await axios.get(`${this.state.host}/get-category`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.categories;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setCategory', categories);
        },
        async getSubCategories(context) {
            let subcategories = await axios.get(`${this.state.host}/get-subcategory`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    console.log(res);
                    return res.data.subcategories;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setSubCategory', subcategories);
        },
        async getHelmets(context) {
            let helmets = await axios.get(`${this.state.host}/get-helmets-category`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    console.log(res);
                    return res.data.helmets;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setHelmets', helmets);
        },
        async getChildCategories(context) {
            let childcategories = await axios.get(`${this.state.host}/get-childcategory`, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    return res.data.childcategories;
                })
                .catch(error => {
                    this.dispatch('notification/error', error);
                });

            context.commit('setChildCategory', childcategories);
        },
        async saveCategory(context, category) {
            let isSuccess = false;
            await axios.post(`${this.state.host}/store-category`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    if(category.type == 1){
                        context.dispatch('getCategories');
                    }else if(category.type == 2){
                        context.dispatch('getSubCategories');
                    }else if(category.type == 4){
                        context.dispatch('getOfferCategory');
                    }else{
                        context.dispatch('getChildCategories');
                    }
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async saveBrand(context, {brand, image}) {
            let isSuccess = false;
            let formData = new FormData();
                formData.append('brand', JSON.stringify(brand));
                formData.append('images', image);
            await axios.post(`${this.state.host}/store-brand`, formData, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getBrand');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });

            return isSuccess;
        },
        async statusUpdate(context, category) {
            await axios.post(`${this.state.host}/catagory-status-update`, category, {
                headers: {
                    Authorization: this.getters['user/GET_AUTH_TOKEN'],
                },
            })
                .then(res => {
                    context.dispatch('getCategories');
                    this.dispatch('notification/success', res.data.data);
                    return isSuccess = true;
                })
                .catch(error => {
                    this.dispatch('notification/error', error.response.data.message);
                });
        },
    }
}
