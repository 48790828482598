export default {
    namespaced: true,
    state: () => ({
        menuItems: [
            { label: 'Dashboard', icon: '/assets/img/icons/dashboard.svg', link: '/dashboard', isRouterLink: true },
            {
              label: 'Product',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Product Management', link: '/product-list', isRouterLink: true },
                { label: 'Category', link: '/category-list', isRouterLink: true },
                { label: 'Sub Category', link: '/subcategory-list', isRouterLink: true },
                { label: 'Child Category', link: '/childcategory-list', isRouterLink: true },
                { label: 'Brand', link: '/brand', isRouterLink: true },
                { label: 'Offer Category', link: '/offer-category', isRouterLink: true },
                { label: 'Product', link: '/product-add', isRouterLink: false },
                { label: 'Product', link: '/product-edit', isRouterLink: false },
              ],
            },
            {
              label: 'Inventory',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                // { label: 'Product Report', link: '/product-report', isRouterLink: true },
                { label: 'Add Quantity', link: '/stock-manage', isRouterLink: true },
                { label: 'Current Stock', link: '/stock-check', isRouterLink: true },
                { label: 'Stock Out', link: '/stock-out', isRouterLink: true },
                { label: 'Low Stock', link: '/low-stock', isRouterLink: true },
                // { label: 'Set Offer', link: '/set-offer', isRouterLink: true },
              ],
            }, 
            {
              label: 'Order',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Pending Order', link: '/pending-order', isRouterLink: true },
                { label: 'Shipped Order', link: '/shipped-order', isRouterLink: true },
                { label: 'Deliverd Order', link: '/complete-order', isRouterLink: true },
                { label: 'Cancel Order', link: '/cancel-order', isRouterLink: true },
                // { label: 'Return Order', link: '/return-order', isRouterLink: true },
              ],
            },
            {
              label: 'Contact',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Newsletter', link: '/email', isRouterLink: true },
                { label: 'Query', link: '/contact', isRouterLink: true },
              ],
            },
            {
              label: 'News',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'News', link: '/news', isRouterLink: true },
              ],
            },
            {
              label: 'Others',
              icon: '/assets/img/icons/product.svg',
              submenu: true,
              submenuItems: [
                { label: 'Slider', link: '/slider', isRouterLink: true },
                { label: 'Featured Product', link: '/featured-product', isRouterLink: true },
                { label: 'Promotional Video', link: '/promotional-video', isRouterLink: true },
                { label: 'Featured Add', link: '/featured-add', isRouterLink: true },
                { label: 'Helmet Filter', link: '/helmet-filter', isRouterLink: true },
                { label: 'Offer Product', link: '/offer-product', isRouterLink: true },
              ],
            },
            {
              label: 'Administration',
              icon: '/assets/img/icons/places.svg',
              submenu: true,
              submenuItems: [
                { label: 'User Management', link: '/user', isRouterLink: true },
              ],
            },
          ],
    }),

    getters: {
        menuItems(state) {
            return state.menuItems;
        } 
    },

    mutations: {
        setMentItem(state, menuItem) {
            state.menuItems = menuItem
        }
    },

    actions: {

    }
  }
  